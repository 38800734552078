import { Ref, SyntheticEvent, useCallback, useRef, useState } from 'react';
import { isFunction } from 'lodash-es';

export type ModalControlModalProps = {
  onClose: () => void;
  show: boolean;
};

export type ModalControlTriggerProps = {
  onClick: (e?: SyntheticEvent) => void;
  ref: Ref<any>;
};

export type ModalControlProps = {
  disableReturnFocus?: boolean;
  focusDelay?: number;
  onClose?: () => void;
};

export type ModalControlResponse = {
  closeModal: () => void;
  openModal: () => void;
  modalProps: ModalControlModalProps;
  triggerProps: ModalControlTriggerProps;
};

/**
 * Hook for managing modal state.
 * Provide props to be spread to a modal (`modalProps`) and a trigger (`triggerProps`) element.
 * Will return focus to the trigger when the modal is closed if the trigger ref is used, otherwise
 * will return focus to the first focusable element on the page. You can disable this behavior,
 * but you should only do that if you plan to manage return focus manually.
 * @param {number} [props.disableReturnFocus] Will disable accessibility. Only do this if you are manually managing return focus when a modal closes.
 * @param {number} [props.focusDelay] Optional delay, useful when working with modals animating from offscreen so the UI doesn't shift off screen.
 * @param {function} [props.onClose] Optional function to run when closing the modal. Should be a stable function (eg via useCallback.)
 */
export function useModalControl({
  disableReturnFocus,
  focusDelay = 0,
  onClose,
}: ModalControlProps = {}): ModalControlResponse {
  const [show, setShow] = useState(false);
  const triggerRef = useRef<any>(null);

  const openModal = useCallback(() => {
    setShow(true);
  }, []);

  const closeModal = useCallback(() => {
    let timeout: ReturnType<typeof setTimeout>;

    setShow(false);
    if (!disableReturnFocus) {
      timeout = setTimeout(() => {
        if (triggerRef.current) {
          triggerRef.current.focus();
        }
      }, focusDelay);
    }
    if (isFunction(onClose)) onClose?.();

    return () => clearTimeout(timeout);
  }, [onClose, disableReturnFocus]);

  return {
    openModal,
    closeModal,
    modalProps: {
      onClose: closeModal,
      show,
    },
    triggerProps: {
      onClick: (e) => {
        e?.stopPropagation?.();
        openModal();
      },
      ref: triggerRef,
    },
  };
}
