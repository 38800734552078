import { useEffect } from 'react';
import {
  safePolygon,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  Placement,
} from '@floating-ui/react';
import { InteractionOptions, MiddlewareOptions } from '../use-popover';
import { usePopoverMenu } from './use-popover-menu';

export type UseNestedPopoverMenuProps = {
  placement?: Placement;
  middlewareOptions?: Partial<MiddlewareOptions>;
  interactionOptions?: Partial<InteractionOptions>;
  showOnHover?: boolean;
};

const getDefaultInteractions = (showOnHover: boolean) => {
  return showOnHover
    ? {
        hover: {
          enabled: true,
          mouseOnly: true,
          handleClose: safePolygon({ blockPointerEvents: true }),
        },
        click: {
          toggle: false,
          ignoreMouse: true,
          enabled: false,
        },
        dismiss: {
          bubbles: true,
        },
        listNavigation: {
          nested: true,
        },
      }
    : {};
};

const defaultMiddlewareOptions = { offset: { mainAxis: 8, crossAxis: -8 } };

export function useNestedPopoverMenu({ showOnHover, ...rest }: UseNestedPopoverMenuProps = {}) {
  const {
    placement = 'right-start',
    interactionOptions: interactionOptionsProps,
    middlewareOptions: middlewareOptionsProps,
  } = rest || {};

  /**
   * enable hover by default
   */
  const interactionOptions = { ...getDefaultInteractions(!!showOnHover), ...interactionOptionsProps };

  const middlewareOptions = { ...defaultMiddlewareOptions, ...middlewareOptionsProps };

  const menu = usePopoverMenu<HTMLElement>({
    placement,
    interactionOptions,
    middlewareOptions,
  });

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();

  useEffect(() => {
    if (!tree) return;

    function handleTreeClick() {
      menu.close();
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        menu.close();
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  useEffect(() => {
    if (menu.isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, menu.isOpen, nodeId, parentId]);

  return menu;
}
