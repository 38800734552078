import * as Sentry from '@sentry/react';

type WeaveFeatures =
  | 'payments'
  | 'contacts'
  | 'reviews'
  | 'phone'
  | 'messages'
  | 'analytics'
  | 'email'
  | 'lists'
  | 'fax'
  | 'team chat'
  | 'scheduling'
  | 'onboarding'
  | 'auth'
  | 'office-assistant'
  | 'infra';

type StringWithAutocomplete<T> = T | (string & Record<never, never>);

type SentryTopic = StringWithAutocomplete<WeaveFeatures>;

type LogSeverityLevel = 'log' | 'info';

type LogType =
  /* Describes a generic breadcrumb. This is typically a log message
or user-generated breadcrumb. */
  | 'default'
  /* This is typically a log message. */
  | 'debug'
  /* An error that occurred before the exception. */
  | 'error'
  /* A navigation event can be a URL change in a web application,
or a UI transition in a mobile or desktop application, etc. */
  | 'navigation'
  /* This represents an HTTP request transmitted from your application. */
  | 'http'
  /* Information that helps identify the root cause of the
issue or for whom the error occurred. */
  | 'info'
  /* This represents a query that was made in your application. */
  | 'query'
  /*  Describes a tracing event. */
  | 'transaction'
  /* A user interaction with your app's UI. */
  | 'ui';

type SentryContext = {
  name: string;
  context: Record<string, any>;
};

export interface SentryLog {
  message: string;
  data?: Record<string, unknown>;
  severityLevel?: LogSeverityLevel;
  type?: LogType;
  category?: SentryTopic | 'log';
}

export const log = ({ message, data, severityLevel = 'info', category = 'log' }: SentryLog) => {
  Sentry.addBreadcrumb({
    category,
    message,
    data,
    level: severityLevel,
  });
};

export interface SentryWarning {
  error: any;
  topic: SentryTopic;
  addContext?: SentryContext;
}

export const warn = ({ error, topic, addContext }: SentryWarning) => {
  Sentry.withScope((scope) => {
    scope.setTag('topic', topic);
    scope.setLevel('warning');
    if (!!addContext) scope.setContext(addContext.name, addContext.context);
    Sentry.captureMessage(error);
  });
};

type ErrorSeverityLevel = 'error' | 'fatal';

export interface SentryError {
  error: any;
  topic: SentryTopic;
  severityLevel?: Extract<Sentry.SeverityLevel, ErrorSeverityLevel>;
  addContext?: SentryContext;
}

export const error = ({ error, topic, severityLevel = 'error', addContext }: SentryError) => {
  Sentry.withScope((scope) => {
    scope.setTag('topic', topic);
    scope.setLevel(severityLevel);
    if (!!addContext) scope.setContext(addContext.name, addContext.context);
    Sentry.captureException(error);
  });
};
