import { contextFactory } from '../../hooks';

type DrawerContextType = {
  labelId?: string;
  descriptionId?: string;
  close?: () => void;
};

export const [DrawerContext, useDrawerContext] = contextFactory<DrawerContextType>(
  'useDrawerContext should be used within a Drawer'
);
