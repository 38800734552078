import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';

export const SlideContainer = ({ children }: PropsWithChildren<unknown>) => {
  return <motion.div css={containerStyle}>{children}</motion.div>;
};

const containerStyle = css({
  display: 'flex',
  height: '100%',
  overflowX: 'hidden',
  position: 'relative',
  transition: 'transform 0.5s ease',
});
