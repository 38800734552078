import { forwardRef, Ref } from 'react';
import { css } from '@emotion/react';
import { FloatingArrow, FloatingNode, FloatingPortal, useFloating, FloatingFocusManager } from '@floating-ui/react';
import { AnimatePresence, motion } from 'motion/react';
import { useStyles } from '../../use-styles';
import { Text } from '../text';
import type { UseTooltipResponse } from './use-tooltip';

type TooltipProps = Omit<UseTooltipResponse['tooltipProps'], 'ref'> & { hasInteractiveContent?: boolean };

type Props = Omit<React.ComponentPropsWithoutRef<'div'>, keyof TooltipProps> & TooltipProps;

// eslint-disable-next-line react/display-name
const TooltipContainer = forwardRef(
  (
    {
      isOpen,
      children,
      arrowRef,
      context,
      theme,
      ...tooltipProps
    }: Omit<Props, 'floatingRef' | 'hasInteractiveContent' | 'nodeId' | 'initial' | 'animate' | 'exit' | 'transition'>,
    ref: Ref<HTMLDivElement>
  ) => {
    const styles = useStyles('Tooltip', 'tooltipStyles', { theme });
    const isChildrenString = typeof children === 'string';

    const { onAnimationStart, onDragStart, onDragEnd, onDrag, ...motionCompatibleProps } = tooltipProps;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.25 }}
        ref={ref}
        css={styles}
        {...motionCompatibleProps}
      >
        {isChildrenString ? (
          <Text
            css={css`
              margin: 0;
              font-size: inherit;
              color: inherit;
              line-height: inherit;
              margin: 0;
            `}
          >
            {children}
          </Text>
        ) : (
          <>{children}</>
        )}
        <FloatingArrow
          className='tooltip-floating-arrow'
          ref={arrowRef}
          context={context}
          height={8}
          width={16}
          tipRadius={2}
        />
      </motion.div>
    );
  }
);

// eslint-disable-next-line react/display-name
export const Tooltip = forwardRef(
  ({ isOpen, nodeId, hasInteractiveContent, floatingRef, ...props }: Props, ref: Ref<HTMLDivElement>) => {
    useFloating({ nodeId: nodeId });

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <AnimatePresence>
            {isOpen && (
              <ConditionalFloatingFocusManager
                hasInteractiveContent={hasInteractiveContent}
                floatingRef={floatingRef}
                context={props.context}
              >
                <TooltipContainer {...props} isOpen={isOpen} ref={ref} />
              </ConditionalFloatingFocusManager>
            )}
          </AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

type ConditionalFloatingFocusManagerProps = {
  children: React.ReactElement;
  context: TooltipProps['context'];
  hasInteractiveContent?: boolean;
  floatingRef?: TooltipProps['floatingRef'];
};
const ConditionalFloatingFocusManager = ({
  hasInteractiveContent,
  context,
  children,
  floatingRef,
}: ConditionalFloatingFocusManagerProps) => {
  return !hasInteractiveContent ? (
    <>{children}</>
  ) : (
    <FloatingFocusManager context={context} initialFocus={floatingRef}>
      {children}
    </FloatingFocusManager>
  );
};
