import appConfig from '@frontend/env';

const backendApi = appConfig.BACKEND_API;

export const APIfetchNoAuth = (): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    return fetch(`${backendApi}${url}`, reqInit).then((res) => {
      if (res.status !== 200) {
        throw new Error(res.body?.toString());
      }
      return res.json();
    });
  };
};

export const authorizedFetch = (token: string): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    reqInit.headers = {
      ...reqInit.headers,
      Authorization: `Bearer ${token}`,
    };
    return fetch(`${backendApi}${url}`, reqInit).then((res) => {
      if (res.status !== 200) {
        return res.json().then((resBody: { code: number; message: string }) => {
          throw new Error(resBody.message);
        });
      }
      return res.json();
    });
  };
};
