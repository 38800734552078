import { ForwardedRef, useRef, useMemo } from 'react';

export const useRefInterceptor = <T extends HTMLElement>(
  ref?: ForwardedRef<T>,
  callback?: (node: T | null) => void
) => {
  const localRef = useRef<T | null>(null);

  const setRef = useMemo(
    () => (node: T | null) => {
      localRef.current = node;

      if (typeof ref === 'function') {
        ref(node);
      } else if (ref?.current !== undefined) {
        ref.current = node;
      }

      callback?.(node);
    },
    [ref, callback]
  );

  return [localRef, setRef] as const;
};
