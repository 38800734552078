import { ComponentPropsWithRef, forwardRef, MouseEventHandler, ReactNode } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { Icon, IconProps } from '@frontend/icons';
import { Text, useNestedPopoverMenu, UseNestedPopoverMenuProps } from '../../';
import { useStyles } from '../../../use-styles';
import { PopoverMenuItem, PopoverMenuItemProps, ElementTypePartial } from './popover-menu-item.component';
import { PopoverMenu } from './popover-menu.component';
import { usePopoverMenu, PropGettersMenu, UsePopoverMenuResponse } from './use-popover-menu';

type PolymorphicRef<C extends ElementTypePartial> = ComponentPropsWithRef<C>['ref'];
type PopoverProps = Parameters<typeof usePopoverMenu<HTMLElement>>[0];
type PopoverResponse = UsePopoverMenuResponse<HTMLElement>;
type GetItemProps = Parameters<PopoverResponse['getItemProps']>[0];

export type PopoverMenuItemDropdownSubItemProps = {
  menu: PopoverResponse;
};

export type PopoverMenuItemDropdownProps<C extends ElementTypePartial> = PopoverMenuItemProps<C> &
  UseNestedPopoverMenuProps & {
    PrefixIcon?: (props: IconProps) => ReactNode;
    prefixIcon?: IconProps['name'] | false;
    subMenuItems?: (props: PopoverMenuItemDropdownSubItemProps) => ReactNode;
    popoverProps?: PopoverProps;
    suffixIcon?: IconProps['name'] | false;
    SuffixIcon?: (props: IconProps) => ReactNode;
    suffixIconColor?: IconProps['color'];
    menuProps?: Parameters<PopoverResponse['getMenuProps']>[0];
    triggerProps?: Parameters<PopoverResponse['getTriggerProps']>[0];
    closeParentMenu?: () => void;
    disableCloseOnSelect?: boolean;
    showOnHover?: boolean;
  };

export type PopoverMenuItemDopdownComponent = <C extends ElementTypePartial = 'button'>(
  props: PopoverMenuItemDropdownProps<C>
) => React.ReactNode;

// eslint-disable-next-line react/display-name
export const PopoverMenuItemDropdown: PopoverMenuItemDopdownComponent = forwardRef(
  <C extends ElementTypePartial = 'button'>(
    {
      children,

      /**
       * Prefix Icon Name
       * If set to `false`, the prefix icon will not be rendered.
       */
      prefixIcon,

      /**
       * Use to override / customize the Icon react node
       */
      PrefixIcon,

      /**
       * Suffix Icon Name
       * If set to `false`, the suffix icon will not be rendered.
       */
      suffixIcon,

      /**
       * Use to override / customize the Icon react node
       */
      SuffixIcon,

      /**
       * Method that renders the sub-menu items
       * Recieves props: { menu: PopoverResponse }
       * Use on child elements to get/bind props.
       *
       * Example getting menu props:
       * ({ menu: { getItemProps, isOpen, ...ect }}) => ReactNode
       */
      subMenuItems,
      active,
      disabled,
      destructive,
      popoverProps: optionalPopoverProps,
      menuProps,
      triggerProps,

      /***
       * If true, main parent menu will not close when a child item is selected.
       */
      disableCloseOnSelect,

      /**
       * If disableCloseOnSelect is false, `closeParentMenu`
       * should be provided, so that a child can close the parent menu.
       */
      closeParentMenu,

      menuId,

      /**
       * If true, will open child menus on hover
       */
      showOnHover = false,

      ...rest
    }: PopoverMenuItemDropdownProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const subMenu = useNestedPopoverMenu({
      showOnHover,
      ...optionalPopoverProps,
    });

    const styles = useStyles('PopoverMenu', 'menuItemDropdown', {
      active: active || subMenu.isOpen,
      disabled,
      destructive,
    });

    const { ref: subItemTriggerRef, ...subMenuTriggerProps } = subMenu.getTriggerProps(triggerProps);
    const subMenuProps = subMenu.getMenuProps(menuProps);

    const renderPrefixIcon = () => {
      if (prefixIcon === false || (!prefixIcon && !PrefixIcon)) return null;

      const IconComponent = PrefixIcon || Icon;

      return IconComponent({ name: prefixIcon!, size: 16, color: 'subdued' });
    };

    const renderSuffixIcon = () => {
      if (suffixIcon === false) return null;

      const IconComponent = SuffixIcon || Icon;

      return (
        <IconComponent
          name={suffixIcon || 'alt-caret-right-tiny'}
          size={8}
          color='default'
          css={css`
            justify-self: flex-end;
          `}
        />
      );
    };

    const getChildItemProps = ((params) => {
      /**
       * Override the default getItemProps() method.
       * Wrap it with 'closeParentMenu' as an additional invocation.
       */
      const { onClick, ...rest } = params ?? {};

      return subMenu.getItemProps({
        ...rest,
        onClick: ((e) => {
          if (!disableCloseOnSelect) {
            closeParentMenu?.();
          }

          if (onClick) {
            onClick(e);
          }
        }) as MouseEventHandler<HTMLButtonElement>,
      } as GetItemProps);
    }) as PropGettersMenu<HTMLMenuElement, false>['getItemProps'];

    return (
      <>
        <PopoverMenuItem
          {...rest}
          {...subMenuTriggerProps}
          ref={composeRefs(ref, subItemTriggerRef)}
          css={styles}
          disabled={disabled}
        >
          {renderPrefixIcon()}

          {typeof children === 'string' ? (
            <Text as='span' className={`menu-item-text__${menuId}`} color={destructive ? 'error' : 'default'}>
              {children}
            </Text>
          ) : (
            children
          )}

          {renderSuffixIcon()}
        </PopoverMenuItem>
        {subMenuItems && (
          <PopoverMenu {...subMenuProps}>
            {subMenuItems({ menu: { ...subMenu, getItemProps: getChildItemProps } })}
          </PopoverMenu>
        )}
      </>
    );
  }
);
