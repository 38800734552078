import {
  CallPopTypes,
  GetWeavePopNotificationActionsByType,
  GetWeavePopNotificationByType,
  WeavePopNotification,
} from '@frontend/types';

declare global {
  interface Window {
    shell?: Adapter;
  }
}

export enum IPCEventName {
  PopAside = 'pop:aside',
  PopMain = 'pop:main',
  ShowNotification = 'notification:show',
  HideNotification = 'notification:hide',
  NotificationAction = 'notification:action',
}

type NotificationId = string;
type TransportEvents = { type: 'locations'; locations: string[] };
type LoggerEvents = { type: 'log' | 'error'; message: string };

type PopEvents =
  | { type: 'navigate'; index: number }
  | { type: 'dismiss'; id: string }
  | { type: 'action'; id: string; action: 'answer' | 'hangup' | 'dismiss' }
  | { type: 'addNotification'; notification: CallPopTypes.Notification }
  | { type: 'removeNotification'; id: string }
  | { type: 'setActiveIndex'; index: number }
  | { type: 'setNotifications'; notifications: CallPopTypes.Notification[] }
  | { type: 'setOutlet'; outlet: 'profile' | 'softphone' | 'queue' };

export type PopActionPayload = Extract<PopEvents, { type: 'action' }>;
export type PopActionHandler = (e: IpcRendererEvent, data: PopActionPayload) => void;

//note, keep these as keys as strings that match the above enum. Using the actual enum makes it harder to use this throughout the app
export type IPCEvents = {
  ['debug:ping']: any;
  ['debug:pong']: any;
  ['debug:enable-debug-mode']: undefined;
  ['debug:disable-debug-mode']: undefined;
  ['info:version']: string | undefined;
  ['info:update-available']: undefined | { version: string; urgency: 'low' | 'medium' | 'high' };
  ['window:open']: { url: string };
  ['window:control']: 'minimize' | 'maximize' | 'close';
  ['pop:main']: PopEvents | { type: 'inspect'; notification: CallPopTypes.Notification };
  ['pop:aside']: PopEvents;
  ['send:locations']: TransportEvents;
  ['handle:console:log']: LoggerEvents;
  ['update:new-version-available']: { version: string; urgency: 'low' | 'medium' | 'high' };
  ['notifications:empty']: undefined;
  ['notification:placement']: 'top-right' | 'bottom-right';
  ['notifications:hide']: undefined;
  ['notifications:resize']:
    | 'reset'
    | {
        width: number | undefined;
        height: number | undefined;
      };
  ['notification:hide']: NotificationId;
  ['notification:show']: {
    notification: WeavePopNotification;
  };
  ['notification:update']: {
    notification: WeavePopNotification;
  };
  ['notification:timed-out']: NotificationId;
  ['window:history:back']: undefined;
  ['window:history:forward']: undefined;
  ['window:history:go']: { step: number };
  ['window:history:clear']: undefined;
  ['window:history:push']: { href: string; title: string };
  //undefined here because it's a two way event. getting history you pass in undefined, but you receive the type here
  ['window:history:get']: undefined | { history: { href: string; title: string }[]; current: number };
  ['window:history:can-go-back']: undefined | boolean;
  ['window:history:can-go-forward']: undefined | boolean;
  ['info:did-navigate']: any;
  ['info:did-navigate-in-page']: any;
  ['did-navigate']: any;
  ['did-navigate-in-page']: any;
  ['window:online:status']: any;
  ['notification:action']: {
    [P in WeavePopNotification['type']]: {
      notification: GetWeavePopNotificationByType<P>;
      action: GetWeavePopNotificationActionsByType<P>;
    };
  }[WeavePopNotification['type']];
  focus: undefined;
  restart: undefined;
};

export type FeatureAvailability = 'shell-theme' | 'recent-history';

//This is a naive version of the real electron.IpcRendererEvent. We probably won't every really need this
export type IpcRendererEvent = {
  preventDefault: () => void;
  ports: MessagePort[];
  sender: any; // IpcRenderer (from electron). Flesh this out if needed, but it probably won't be;
  senderFrame?: {
    url: string;
  };
};
export type IPCRendererCallback<Event extends keyof IPCEvents = keyof IPCEvents> = (
  e: IpcRendererEvent,
  data: IPCEvents[Event]
) => void;

export type Adapter = {
  featureAvailability: Set<FeatureAvailability>;
  isShell: boolean;
  isMac: boolean;
  isWindows: boolean;
  isLinux: boolean;
  on: <Event extends keyof IPCEvents, CB extends IPCRendererCallback<Event>>(
    e: Event,
    callback: CB,
    id?: string
  ) => void;
  off: <Event extends keyof IPCEvents, CB extends IPCRendererCallback<Event>>(
    e: Event,
    callback: CB,
    id?: string
  ) => void;
  version: string;
  flavor: string;
  emit: <Event extends keyof IPCEvents, Payload extends IPCEvents[Event]>(e: Event, payload: Payload) => void;
  invoke: <Event extends keyof IPCEvents, Payload extends IPCEvents[Event]>(e: Event, payload: Payload) => Promise<any>;
  context: 'main' | 'alerts';
  focus: () => void;
  restart: () => void;
};

export type Shell = {
  [P in keyof Omit<Adapter, 'isShell'>]: Adapter[P] | undefined;
} & {
  isShell: boolean;
};

export const shell: Shell = {
  featureAvailability: window.shell?.featureAvailability,
  isShell: !!window.shell,
  isMac: window.shell?.isMac,
  isWindows: window.shell?.isWindows,
  isLinux: window.shell?.isLinux,
  version: window.shell?.version,
  flavor: window.shell?.flavor,
  on: window.shell?.on,
  off: window.shell?.off,
  emit: window.shell?.emit,
  invoke: window.shell?.invoke,
  focus: window.shell?.focus,
  restart: window.shell?.restart,
  context: window.shell?.context,
};
