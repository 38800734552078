import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '../../button';
import { Heading, type HeadingProps } from '../../heading';
import { useDrawerContext } from '../context';

const Title = (props: HeadingProps) => <Heading level={2} css={{ margin: 0 }} {...props} />;

const Close = (props: Omit<ButtonProps, 'variant'>) => {
  const { close } = useDrawerContext();
  return <Button variant='secondary' iconName={props?.iconName ?? 'x'} onClick={close} {...props} />;
};

export const DrawerHeaderOptional = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & { children: React.ReactNode }
>(({ children, ...rest }, ref) => {
  return (
    <header
      ref={ref}
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        position: 'relative',
      }}
      {...rest}
    >
      {children}
    </header>
  );
});

DrawerHeaderOptional.displayName = 'DrawerHeader';

const DrawerHeaderNamespace = Object.assign(DrawerHeaderOptional, {
  Title,
  Close,
});

export { DrawerHeaderNamespace as DrawerHeader };
