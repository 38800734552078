import { forwardRef, ReactNode, Ref } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { FloatingFocusManager, FloatingNode, FloatingPortal, FloatingOverlay, useFloating } from '@floating-ui/react';
import { AnimatePresence, motion, MotionProps, Variants } from 'motion/react';
import { theme } from '@frontend/theme';
import { transparentize } from '../../../helpers';
import { contextFactory, useThemeValues } from '../../../hooks';
import { VerticalSlideModalContent, VerticalSlideModalHeader } from './atoms';
import { UseDialogResponse } from './use-vertical-slide-modal';

type VerticalSlideModalDirection = 'up' | 'down';

type Props = UseDialogResponse['modalProps'] & {
  children: ReactNode;
  initialFocus?: number | React.MutableRefObject<HTMLElement | null>;
  rootId?: string;
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
  returnFocus?: boolean;
  direction?: VerticalSlideModalDirection;
} & React.HTMLAttributes<HTMLDivElement> &
  MotionProps;

const ANIMATION_DURATION = 0.3;
const BACKDROP_TRANSPARENCY_LEVEL = 0.7;

const modalVariants: Record<VerticalSlideModalDirection, Variants> = {
  up: {
    hidden: {
      transform: 'translate(0,100%)',
    },
    visible: {
      transform: 'translate(0,0)',
      transition: {
        duration: ANIMATION_DURATION,
      },
    },
    exit: {
      transform: 'translate(0,100%)',
      transition: {
        duration: ANIMATION_DURATION,
      },
    },
  },
  down: {
    hidden: {
      transform: 'translate(0,-100%)',
    },
    visible: {
      transform: 'translate(0,0)',
      transition: {
        duration: ANIMATION_DURATION,
      },
    },
    exit: {
      transform: 'translate(0,-100%)',
      transition: {
        duration: ANIMATION_DURATION,
      },
    },
  },
};

const backgroundVariants = {
  initial: {
    background: transparentize(1, theme.colors.primary90),
  },
  animate: {
    background: transparentize(BACKDROP_TRANSPARENCY_LEVEL, theme.colors.primary90),
    transition: {
      duration: ANIMATION_DURATION,
    },
  },
  exit: {
    background: transparentize(1, theme.colors.primary90),
    transition: {
      duration: ANIMATION_DURATION,
    },
  },
};

type VerticalSlideModalContext = {
  labelId?: string;
  descriptionId?: string;
  close?: () => void;
};

export const [VerticalSlideModalContext, useVerticalSlideModalContext] = contextFactory<VerticalSlideModalContext>();

const ModalBase = forwardRef(
  (
    {
      children,
      labelId,
      descriptionId,
      close,
      direction = 'up',
      hasSpecifiedRoot,
      ...popoverProps
    }: Omit<Props, 'context' | 'nodeId' | 'isOpen' | 'ref' | 'initialFocus' | 'returnFocus'> & {
      hasSpecifiedRoot: boolean;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useThemeValues();

    const directionSpecificStyles: Record<VerticalSlideModalDirection, SerializedStyles> = {
      up: css`
        border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
        bottom: 0;
      `,
      down: css`
        border-radius: 0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium};
        top: 0;
      `,
    };

    const { onAnimationStart, onDragStart, onDragEnd, onDrag, ...motionCompatibleProps } = popoverProps;

    return (
      <motion.article
        initial='hidden'
        animate='visible'
        exit='exit'
        variants={modalVariants[direction]}
        ref={ref}
        style={{ willChange: 'transform' }}
        css={[
          (theme) => css`
            box-shadow: ${theme.shadows.heavy};
            border: none;
            overflow: auto;
            background: ${theme.colors.white};
            display: flex;
            flex-direction: column;
            height: calc(100% - 24px - ${theme.heightOffset}px);
            width: 100%;
            bottom: 0;
            :focus {
              outline: none;
            }
            z-index: ${theme.zIndex.modals};
          `,
          directionSpecificStyles[direction],
          !hasSpecifiedRoot &&
            css`
              position: fixed;
            `,
        ]}
        aria-describedby={descriptionId}
        aria-labelledby={labelId}
        aria-modal
        {...motionCompatibleProps}
      >
        {children}
      </motion.article>
    );
  }
);

//@ts-ignore This works fine, just the types don't like each other
const BackdropOverlay = motion(FloatingOverlay);

export const VerticalSlideModalOptional = forwardRef(
  (
    { isOpen, nodeId, context, initialFocus, rootId, root, returnFocus = true, ...props }: Props,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    const hasSpecifiedRoot = !!rootId || !!root;
    useFloating({ nodeId });

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal id={rootId} root={root}>
          <AnimatePresence>
            {isOpen ? (
              <BackdropOverlay
                initial='initial'
                animate='animate'
                exit='exit'
                variants={backgroundVariants}
                css={(theme) => css`
                  background: ${transparentize(BACKDROP_TRANSPARENCY_LEVEL, theme.colors.primary90)};
                  z-index: ${theme.zIndex.modals};
                  top: ${theme.heightOffset}px !important;
                `}
                id='vertical-slide-modal-backdrop'
                style={
                  hasSpecifiedRoot
                    ? {
                        position: 'absolute',
                      }
                    : undefined
                }
                lockScroll
              >
                <FloatingFocusManager context={context} initialFocus={initialFocus} returnFocus={returnFocus}>
                  <VerticalSlideModalContext.Provider
                    value={{
                      labelId: props.labelId,
                      descriptionId: props.descriptionId,
                      close: props.close,
                    }}
                  >
                    <ModalBase {...props} ref={ref} hasSpecifiedRoot={hasSpecifiedRoot} />
                  </VerticalSlideModalContext.Provider>
                </FloatingFocusManager>
              </BackdropOverlay>
            ) : null}
          </AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

/**
 * If providing `rootId` or `root`, ensure that the root element has a position that is not `static` for the modal to
 * be contained within the root element.
 */
const VerticalSlideModalNamespace = Object.assign(VerticalSlideModalOptional, {
  Header: VerticalSlideModalHeader,
  Content: VerticalSlideModalContent,
});

export { VerticalSlideModalNamespace as VerticalSlideModal };
