import { motion } from 'motion/react';
import { useStyles } from '../../use-styles';
import { Button } from '../button';
import { ContextMenuActions } from '../context-menu';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../popover';
import { useChatBubbleContext } from './chat';
import { easeInOutVariants } from './chat-theme';
import { PrimaryAction } from './types';

type ActionsProps = {
  primaryAction?: PrimaryAction;
  trackingId?: string;
  actions?: ContextMenuActions;
  hasError?: boolean;
  onRetry?: () => void;
};
export const Actions = ({ primaryAction, trackingId, actions, hasError, onRetry }: ActionsProps) => {
  const { direction, hoverProps } = useChatBubbleContext();
  const actionStyles = useStyles('Chat', 'actions');
  const { getMenuProps, getTriggerProps, getItemProps } = usePopoverMenu({
    placement: direction === 'outbound' ? 'left-start' : 'right-start',
  });

  return (
    <motion.div
      className='chat-actions'
      css={actionStyles}
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={easeInOutVariants}
      {...hoverProps}
    >
      {primaryAction && (
        <Button
          className='primary-actions-button'
          hoverLabel={primaryAction.label}
          trackingId={primaryAction.trackingId}
          onClick={primaryAction.onClick}
          iconName={primaryAction.icon}
          variant='secondary'
        />
      )}
      {hasError && <Button className='error-actions-button' onClick={onRetry} iconName='update' variant='secondary' />}
      {!hasError && actions && actions.length > 0 && (
        <Button
          className='more-actions-button'
          {...getTriggerProps()}
          trackingId={trackingId}
          iconName='more'
          variant='secondary'
        />
      )}
      <PopoverMenu {...getMenuProps()} returnFocus={false}>
        {actions?.map(({ label, onClick, ...action }, index) => (
          <PopoverMenuItem
            key={`chat-item-more-action-${action.trackingId ?? index}`}
            {...getItemProps({
              index,
              onClick,
            })}
            {...action}
          >
            {label}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </motion.div>
  );
};
