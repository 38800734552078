import type { ChangeEvent } from 'react';
import { isFunction } from 'lodash-es';
import { hasValue } from '../shared/utils';
import type {
  BaseFieldState,
  CustomChangeEvent,
  FieldKey,
  FormConfig,
  FormErrors,
  FormFieldState,
  FormState,
  FormValues,
  ResolvedFieldConfig,
  ValidationResult,
  Validator,
  ValidatorFieldState,
  ValidatorFn,
} from './types';
import * as validators from './validators';
import { setValue } from './value-setters';

export const REQUIRED_ERROR = 'This field is required';

export const validationPipe =
  <T extends FieldKey>(validator?: Validator<T>) =>
  (props: ValidatorFieldState<T>, allFields?: any) => {
    const hasSomeValue = hasValue(props.value);
    if (props.required && !hasSomeValue) return REQUIRED_ERROR;
    const hasValidatableValue = hasSomeValue || Array.isArray(props.value);
    return hasValidatableValue && isFunction(validator)
      ? //@ts-ignore = probably a type mismatch based on the generics
        validator(props, allFields)
      : '';
  };

// TODO: refactor quick fix on validator where its assuming validator might be undefined
export const getError = <T extends FieldKey>(
  { type, validator, ...rest }: FormFieldState<T>,
  allFields?: any
): string => {
  //@ts-ignore = probably a type mismatch based on the generics
  const customValidator: ValidatorFn<T> | null = isFunction(validator)
    ? //@ts-ignore = probably a type mismatch based on the generics
      validationPipe(validator)
    : null;
  if (isFunction(validators[type as string])) {
    const error = validationPipe<T>(validators[type as string])(rest);
    if (!error && customValidator) return customValidator(rest, allFields);
    return error;
  } else if (customValidator) {
    return customValidator(rest, allFields);
  } else if (rest.required) {
    return validationPipe<T>()(rest);
  }
  return '';
};

export const validateField = <T extends FieldKey>(field: FormFieldState<T>, allFields?: any): ValidationResult => {
  const error = getError(field, allFields);
  return { error, 'aria-invalid': field.touched && !!error };
};

export const normalizeChangeHandler =
  (execute: (props: CustomChangeEvent) => void) =>
  (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | CustomChangeEvent) => {
    if (event.hasOwnProperty('target')) {
      const evt = event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
      execute({ name: evt.target.name, value: evt.target.value });
    } else {
      execute(event as CustomChangeEvent);
    }
  };

export const defaultFieldState: BaseFieldState = {
  active: false,
  'aria-invalid': false,
  autoComplete: 'off',
  error: '',
  touched: false,
};

// Everything not listed here is a string type
const defaultValueByType = {
  checkbox: false,
  checklist: [],
  dateRange: [],
  list: [],
  multiselect: [],
  selectlist: [],
  timeRange: [],
  switch: false,
};

export const getDefaultFieldValue = (type: FieldKey) => defaultValueByType[type] ?? '';

export const initializeFieldState = <T extends FieldKey>(config: ResolvedFieldConfig<T>): FormFieldState<T> => {
  const defaultValue = getDefaultFieldValue(config.type);
  // enforce correct type for incoming start value
  const initialValue = typeof config.value === typeof defaultValue ? config.value : defaultValue;

  const field = setValue(
    {
      ...defaultFieldState,
      ...config,
      value: defaultValue,
    } as any,
    initialValue,
    true
  );
  // passing empty second arg in case it's useForm with custom validator
  return { ...field, ...validateField(field, {}) };
};

const hasError = (error?: string, touched?: boolean): boolean => !!error && !!touched;

export const formIsComplete = <T extends FormConfig>(fields: FormState<T>) =>
  Object.values(fields).every((field) => {
    const { error, hidden, required, touched, validator } = field;
    if (hidden) return true;
    if (required) {
      return !field.error;
    } else if (validator) {
      return !hasError(error, touched);
    }
    return !hasError(error, touched);
  });

export const trimIfString = <T extends any>(value: T) => (typeof value === 'string' ? value.trim() : value);

/** Returns all non-hidden field values */
export const getFieldValues = <T extends FormConfig>(state: FormState<T>): FormValues<T> =>
  Object.entries(state).reduce((obj, [name, { hidden, value }]) => {
    if (hidden) return obj;
    return { ...obj, [name]: trimIfString(value) };
  }, {} as FormValues<T>);

/** Returns all non-hidden field errors, regardless of touched status */
export const getFieldErrors = <T extends FormConfig>(state: FormState<T>): FormErrors<T> =>
  Object.entries(state).reduce((obj, [name, { error, hidden }]) => {
    if (!!error && !hidden) return { ...obj, [name]: error };
    return obj;
  }, {} as FormErrors<T>);

const equals = (value1, value2) => {
  if (Array.isArray(value1)) {
    // obvious failure
    if (value1.length !== value2?.length) return false;
    // same values in same order
    if (value1.toString() === value2?.toString()) return true;
    // same values, different order
    for (const item of value1) {
      if (!value2?.includes(item)) return false;
    }
    return true;
  }
  return value1 === value2;
};

type DiffProps<T extends FormConfig> = {
  initial: FormValues<T>;
  current: FormValues<T>;
};

export const diffValues = <T extends FormConfig>({ current, initial }: DiffProps<T>): FormValues<T> | null => {
  const merged = { ...initial, ...current };
  const diff = Object.entries(merged).reduce((obj, [key, value]) => {
    if (equals(value, initial[key])) return obj;
    return { ...obj, [key]: value };
  }, {} as FormValues<T>);
  return Object.keys(diff).length ? diff : null;
};
