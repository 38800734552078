import { theme as themeOriginal } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type AudioScrubberThemeValues = {
  progressSliderThumbColor: WeaveThemeValues['Color'];
  rangeBackgroundColor: WeaveThemeValues['Color'];
  rangeProgressColor: WeaveThemeValues['Color'];
};

export const audioScrubberTheme: AudioScrubberThemeValues = {
  progressSliderThumbColor: theme.colors.primary50,
  rangeBackgroundColor: theme.colors.neutral30,
  rangeProgressColor: theme.colors.primary50,
};

export const audioScrubberThemeOriginal: AudioScrubberThemeValues = {
  progressSliderThumbColor: themeOriginal.colors.weaveBlue,
  rangeBackgroundColor: themeOriginal.colors.gray300,
  rangeProgressColor: themeOriginal.colors.weaveBlue,
};
