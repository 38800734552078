import { motion, AnimatePresence } from 'motion/react';
import { theme } from '@frontend/theme';
import { CheckIconSmall } from '../../../icon';
import { Heading } from '../../heading';
import { useStepperContext } from '../provider/stepper.provider';
import * as styles from '../stepper.styles';

export type StepperVerticalProps = {
  step: number;
};

export const StepperVertical = ({ step }: StepperVerticalProps) => {
  const { stepStatus } = useStepperContext();
  const heightValue = 'calc(100% + 24px)';

  const stepLine = {
    active: {
      height: '40%',
      transition: {
        delay: 1,
      },
    },
    currActive: {
      height: heightValue,
      transition: {
        delay: 1,
      },
    },
    errorActive: {
      height: heightValue,
      transition: {
        delay: 1,
      },
    },
    completed: {
      height: heightValue,
      transition: {
        delay: 0,
      },
    },
    error: {
      height: '0%',
    },
    inactive: {
      height: '0%',
    },
    initial: {
      height: 0,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
  };

  const stepNum = {
    active: {
      backgroundColor: theme.colors.primary50,
      border: `2px solid ${theme.colors.primary50}`,
      color: theme.colors.white,
    },
    inactive: {
      backgroundColor: theme.colors.white,
      color: theme.colors.neutral40,
      border: `2px solid ${theme.colors.neutral40}`,
    },
    initial: {
      backgroundColor: theme.colors.neutral40,
      color: theme.colors.primary50,
      border: `2px solid ${theme.colors.neutral40}`,
    },
    completed: {
      color: theme.colors.primary50,
      backgroundColor: theme.colors.white,
      border: `2px solid ${theme.colors.primary50}`,
      transition: {
        delay: 0,
        duration: 0.5,
      },
    },
    currActive: {
      backgroundColor: theme.colors.primary50,
      border: `2px solid ${theme.colors.primary50}`,
      color: theme.colors.white,
    },
    errorActive: {
      backgroundColor: theme.colors.status.critical,
      border: `2px solid ${theme.colors.status.critical}`,
      color: theme.colors.white,
    },
    error: {
      backgroundColor: theme.colors.status.critical,
      border: `2px solid ${theme.colors.status.critical}`,
      color: theme.colors.white,
    },
  };

  return (
    <motion.section>
      <div className='step-line' css={styles.stepLine('inactive')}></div>
      <motion.div
        animate={stepStatus[step]}
        variants={stepLine}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        className='step-line'
        css={styles.stepLine(stepStatus[step]!)}
      ></motion.div>
      <motion.div
        animate={stepStatus[step]}
        variants={stepNum}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        className='step-num'
        css={styles.stepNum(stepStatus[step]!)}
      >
        {stepStatus[step] === 'completed' ? (
          <AnimatePresence>
            <CheckIconSmall />
          </AnimatePresence>
        ) : (
          <Heading level={2}>{step}</Heading>
        )}
      </motion.div>
    </motion.section>
  );
};
