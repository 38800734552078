import { css } from '@emotion/react';
import { Variants } from 'motion/react';
import { DrawerSide } from './types';

export const drawerSizes = {
  small: 360,
  smallXL: 400,
  smallXXL: 434,
  medium: 500,
  large: 600,
  xlarge: 700,
} as const;

export const createDrawerVariants = (keepMounted: boolean): Record<DrawerSide, Variants> => ({
  left: {
    hidden: {
      transform: 'translateX(-100%)',
      opacity: 0,
      ...(keepMounted && { display: 'none' }),
    },
    visible: {
      transform: 'translateX(0)',
      opacity: 1,
      ...(keepMounted && { display: 'block' }),
    },
    exit: {
      transform: 'translateX(-100%)',
      ...(keepMounted && { display: 'none' }),
    },
  },
  right: {
    hidden: {
      transform: 'translateX(100%)',
      opacity: 0,
      ...(keepMounted && { display: 'none' }),
    },
    visible: {
      transform: 'translateX(0)',
      opacity: 1,
      ...(keepMounted && { display: 'block' }),
    },
    exit: {
      transform: 'translateX(100%)',
      ...(keepMounted && { display: 'none' }),
    },
  },
  top: {
    hidden: { transform: 'translateY(-100%)', opacity: 0 },
    visible: { transform: 'translateY(0)', opacity: 1 },
    exit: { transform: 'translateY(-100%)', opacity: 0 },
  },
  bottom: {
    hidden: { transform: 'translateY(100%)', opacity: 0 },
    visible: { transform: 'translateY(0)', opacity: 1 },
    exit: { transform: 'translateY(100%)', opacity: 0 },
  },
});

export const backdropVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const sideStyles = (heightOffset: number) => ({
  left: css`
    left: 0;
    top: ${heightOffset}px;
    bottom: 0;
  `,
  right: css`
    right: 0;
    top: ${heightOffset}px;
    bottom: 0;
  `,
  top: css`
    top: ${heightOffset}px;
    left: 0;
    right: 0;
  `,
  bottom: css`
    bottom: 0;
    left: 0;
    right: 0;
  `,
});

export const drawerBackdropId = 'drawer-backdrop-overlay';
