import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { nanoid } from 'nanoid';
import { theme } from '@frontend/theme';
import { usePortal } from '../../hooks';
import { Alert } from './alert';
import { AlertData, AlertType } from './types';

type AlertByTypePayload = string | Omit<AddAlertPayload, 'type'>;

type AlertContextType = {
  addAlert: (payload: AddAlertPayload) => string;
  clearAlerts: () => void;
  error: (payload: AlertByTypePayload) => string;
  info: (payload: AlertByTypePayload) => string;
  removeAlert: (id: string) => void;
  subscribe: (fn: AlertSubscriptionFn) => () => void;
  success: (payload: AlertByTypePayload) => string;
  warning: (payload: AlertByTypePayload) => string;
};

export const AlertsStateContext = createContext<AlertData[]>([]);

export const AlertsContext = createContext<AlertContextType>({} as AlertContextType);

export type AddAlertPayload = Omit<AlertData, 'id'> & {
  autoDismissAfter?: number;
  id?: string;
};

type AlertSubscriptionPayload = {
  action: 'add' | 'clear' | 'remove';
  ids: string[];
};
export type AlertSubscriptionFn = (payload: AlertSubscriptionPayload) => void;

const DEFAULT_DELAY = 6000;

export const AlertManager = (() => {
  let addAlertFn: (payload: AddAlertPayload) => string;
  let removeAlertFn: (id: string) => void;
  let clearAlertsFn: () => void;

  const setAlertFunctions = (
    addFn: typeof addAlertFn,
    removeFn: typeof removeAlertFn,
    clearFn: typeof clearAlertsFn
  ) => {
    addAlertFn = addFn;
    removeAlertFn = removeFn;
    clearAlertsFn = clearFn;
  };

  const addAlert = (payload: AddAlertPayload) => addAlertFn(payload);
  const removeAlert = (id: string) => removeAlertFn(id);
  const clearAlerts = () => clearAlertsFn();

  return { addAlert, removeAlert, clearAlerts, setAlertFunctions };
})();

export const AlertsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const timersRef = useRef<Map<string, number>>(new Map());
  const subscribersRef = useRef<{ [key: string]: AlertSubscriptionFn }>({});
  const alertsListUid = nanoid();

  const unsubscribe = (id: string) => () => {
    delete subscribersRef.current[id];
  };

  const publish = (payload: AlertSubscriptionPayload) => {
    Object.values(subscribersRef.current).forEach((fn) => fn(payload));
  };

  const clearTimer = (id: string) => {
    if (timersRef.current.has(id)) {
      window.clearTimeout(timersRef.current.get(id));
      timersRef.current.delete(id);
    }
  };

  const removeAlert = useCallback((id: string) => {
    clearTimer(id);
    setAlerts((curAlerts) => curAlerts.filter((alert) => alert.id !== id));
    publish({ action: 'remove', ids: [id] });
  }, []);

  const startTimer = (id: string, delay: number) => {
    clearTimer(id);
    timersRef.current.set(
      id,
      window.setTimeout(() => {
        removeAlert(id);
      }, delay)
    );
  };

  const contextValue = useMemo(() => {
    const addAlert = (payload: AddAlertPayload) => {
      const { autoDismissAfter = DEFAULT_DELAY, id, message, type, action } = payload;
      const uid = id ?? nanoid();
      setAlerts((curAlerts) => [...curAlerts, { id: uid, message, type, action }]);
      if (autoDismissAfter > 0) startTimer(uid, autoDismissAfter);
      publish({ action: 'add', ids: [uid] });
      return uid;
    };

    const alertByType = (type: AlertType) => (payload: AlertByTypePayload) => {
      if (typeof payload === 'string') {
        return addAlert({ type, message: payload });
      } else {
        return addAlert({ ...payload, type });
      }
    };

    AlertManager.setAlertFunctions(addAlert, removeAlert, () => {
      setAlerts((curAlerts) => {
        publish({ action: 'clear', ids: curAlerts.map((alert) => alert.id) });
        return [];
      });
    });

    return {
      addAlert,
      clearAlerts: AlertManager.clearAlerts,
      error: alertByType('error'),
      info: alertByType('info'),
      removeAlert,
      subscribe: (fn: AlertSubscriptionFn) => {
        const uid = nanoid();
        subscribersRef.current[uid] = fn;
        return unsubscribe(uid);
      },
      success: alertByType('success'),
      warning: alertByType('warning'),
    };
  }, [setAlerts]);

  useEffect(() => {
    return () => {
      timersRef.current.clear();
    };
  }, []);

  const { Portal } = usePortal({
    attributes: { id: `alertsList-${alertsListUid}` },
  });

  return (
    <AlertsContext.Provider value={contextValue}>
      {children}
      <AlertsStateContext.Provider value={alerts}>
        <Portal>
          <AlertsList />
        </Portal>
      </AlertsStateContext.Provider>
    </AlertsContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertsContext);
  if (typeof context === 'undefined') {
    throw new Error('useAlert must be used inside an AlertsProvider');
  }
  return {
    error: context.error,
    info: context.info,
    success: context.success,
    warning: context.warning,
  };
};

export const AlertsList = () => {
  const alerts = useContext(AlertsStateContext);
  const { removeAlert } = useContext(AlertsContext);

  return (
    <motion.div
      css={css`
        width: 100%;
        max-width: ${theme.spacing(52.5)};
        height: 100%;
        padding: ${theme.spacing(2)};
        pointer-events: none;
        position: fixed;
        top: 0;
        right: 0;
        z-index: ${theme.zIndex.alerts};
      `}
    >
      <AnimatePresence mode='popLayout'>
        {alerts.map(({ id, message, ...rest }, index) => {
          return (
            <Alert key={id} id={id} index={index} onClick={removeAlert} {...rest}>
              {message}
            </Alert>
          );
        })}
      </AnimatePresence>
    </motion.div>
  );
};
