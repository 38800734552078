import React, { forwardRef, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { useThemeValues } from '../../../hooks';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useStyles } from '../../../use-styles';
import { useAccordionItemContext } from '../atoms/accordion-item';
import { useAccordionContext } from '../provider/accordion.provider';

type AccordionBodyProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C>;

type AccordionBodyComponent = <C extends React.ElementType = 'section'>(
  props: AccordionBodyProps<C>
) => React.ReactNode;

export const AccordionBody: AccordionBodyComponent = forwardRef(
  <C extends React.ElementType = 'section'>(
    { children, as, ...rest }: AccordionBodyProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const {
      isItemActive,
      getBodyId,
      onAnimationStart,
      onAnimationEnd,
      onAnimationComplete,
      getHeaderId,
      shouldRenderBody,
      variant,
    } = useAccordionContext();
    const { value } = useAccordionItemContext();
    const { spacing } = useThemeValues();
    const isOpen = isItemActive(value);
    const accordionBodyStyles = useStyles('Accordion', 'accordionBody', {
      variant,
    });

    const Component = as ? motion[as as any] : motion.section;

    const conditionalAnimationProps = shouldRenderBody
      ? { animate: isOpen ? 'open' : 'collapsed' }
      : { animate: 'open', exit: 'collapsed' };

    return (
      <ConditionalAnimatePresence isOpen={isOpen} shouldRenderBody={shouldRenderBody}>
        <motion.section
          key='content'
          initial='collapsed'
          {...conditionalAnimationProps}
          variants={{
            open: {
              height: 'auto',
              ...(shouldRenderBody ? { display: 'block' } : {}),
              ...(variant !== 'location' && { opacity: 1 }),
            },
            collapsed: {
              height: 0,
              ...(shouldRenderBody ? { transitionEnd: { display: 'none' } } : {}),
              ...(variant !== 'location' && { opacity: 0 }),
            },
          }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          role='region'
          id={getBodyId(value)}
          onAnimationStart={onAnimationStart}
          onAnimationEnd={onAnimationEnd}
          onAnimationComplete={onAnimationComplete}
          aria-labelledby={getHeaderId(value)}
          ref={ref}
          css={[
            css`
              overflow: hidden;
            `,
            accordionBodyStyles,
          ]}
        >
          <Component
            css={css`
              padding: ${spacing(2)};
            `}
            {...rest}
          >
            {children}
          </Component>
        </motion.section>
      </ConditionalAnimatePresence>
    );
  }
);

type ConditionalAnimatePresenceProps = PropsWithChildren<{ isOpen: boolean; shouldRenderBody?: boolean }>;

const ConditionalAnimatePresence = ({ isOpen, shouldRenderBody, children }: ConditionalAnimatePresenceProps) => {
  return shouldRenderBody ? <>{children}</> : <AnimatePresence initial={false}>{isOpen && children}</AnimatePresence>;
};
