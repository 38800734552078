import { Interpolation, Theme } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { Modal } from '../modal';
import type { ModalControlModalProps } from '../provider';
import { ModalProviderProps, useModalContext } from '../provider';
import { ConfirmationText } from './confirmation-text';

export type ConfirmationModalProps = ModalControlModalProps &
  Pick<ModalProviderProps, 'mountTarget' | 'prepend'> & {
    cancelLabel?: string;
    confirmLabel?: string;
    cancelTrackingId?: string;
    confirmTrackingId?: string;
    modalCss?: Interpolation<Theme>;
    message?: React.ReactNode;
    onCancel?: () => void;
    onConfirm: () => void;
    title: string;
    children?: React.ReactNode;
    destructive?: boolean;
    maxWidth?: number;
    backLabel?: string;
    onBackClick?: () => void;
    backTrackingId?: string;
  };

type ContentProps = Omit<ConfirmationModalProps, 'mountTarget' | 'prepend' | 'show'>;

const ConfirmationModalContent = ({
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  cancelTrackingId,
  confirmTrackingId,
  backLabel,
  onBackClick,
  backTrackingId,
  children,
  message,
  onCancel,
  onConfirm,
  onClose,
  title,
  destructive = false,
}: ContentProps) => {
  const { setLoading } = useModalContext();

  const maybeAsyncHandler = (handler: () => void) => async () => {
    try {
      const result: any = handler();
      if (result instanceof Promise) {
        setLoading(true);
        await result;
        setLoading(false);
      }
      onClose();
    } catch {
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <Modal.Header onClose={onClose}>{title}</Modal.Header>
      <Modal.Body>
        {message && <ConfirmationText>{message}</ConfirmationText>}
        {children}
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: confirmLabel,
          trackingId: confirmTrackingId,
          onClick: maybeAsyncHandler(onConfirm),
          destructive,
        }}
        secondary={{
          label: cancelLabel,
          trackingId: cancelTrackingId,
          onClick: isFunction(onCancel) ? maybeAsyncHandler(onCancel) : onClose,
        }}
        tertiary={{
          label: backLabel,
          trackingId: backTrackingId,
          onClick: onBackClick,
        }}
      />
    </>
  );
};

export const ConfirmationModal = ({
  mountTarget,
  prepend,
  show,
  maxWidth = 400,
  modalCss,
  ...rest
}: ConfirmationModalProps) => (
  <Modal
    show={show}
    onClose={rest.onClose}
    maxWidth={maxWidth}
    mountTarget={mountTarget}
    prepend={prepend}
    css={modalCss}
  >
    <ConfirmationModalContent {...rest} />
  </Modal>
);
