import { borderRadius } from './border-radius';
import { colors } from './colors';
import { font, fontSize } from './font';
import { shadows } from './shadows';
import { spacing, GRID_UNIT } from './spacing';
import { zIndex } from './z-index';

export { GRID_UNIT };

// The JS values of the theme, as opposed to CSS vars
export const weaveTheme = {
  borderRadius,
  colors,
  font,
  fontSize,
  heightOffset: 0,
  name: 'spark',
  shadows,
  spacing,
  zIndex,
} as const;

export type WeaveTheme = typeof weaveTheme;
