import React, { useRef, useMemo } from 'react';
import { css, type Interpolation } from '@emotion/react';
import { AnimatePresence, motion, type MotionProps } from 'motion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button } from '../button';
import { SkeletonLoader } from '../loader/skeleton-loader';
import { Text } from '../text';
import { useImageLoader, MAX_RETRY_LIMIT } from './hooks';

export type ImageProps = MotionProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    placeholderSrc?: string;
    aspectRatio?: number;
    borderRadius?: number | string;
    className?: string;
    containerStyle?: Interpolation;
    containerClassName?: string;
    onRetry?: () => void;
    objectFit?: 'fill' | 'cover' | 'contain';
    allowRetry?: boolean;
    imageStyle?: Interpolation;
  };

const Placeholder: React.FC<{
  placeholderSrc?: string;
  alt?: string;
  imageStyles: Interpolation[];
}> = ({ placeholderSrc, alt, imageStyles }) => (
  <motion.div
    css={placeholderStyle}
    initial={{ opacity: 1 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, transition: { duration: 0.3 } }}
  >
    {placeholderSrc ? (
      <img
        src={placeholderSrc}
        alt={`Placeholder for ${alt}`}
        css={[
          imageStyles,
          css`
            background: black;
          `,
        ]}
      />
    ) : (
      <SkeletonLoader width='100%' height='100%' />
    )}
  </motion.div>
);

const ErrorState: React.FC<{
  alt?: string;
  allowRetry: boolean;
  retryCount: number;
  handleRetry: () => void;
}> = ({ alt, allowRetry, retryCount, handleRetry }) => (
  <div css={errorContainerStyle} role='alert' aria-live='polite'>
    <Icon
      name='error-badge'
      color='error'
      css={css`
        margin-bottom: ${theme.spacing(0.5)};
      `}
      aria-hidden='true'
      size={60}
    />
    <div
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
    >
      <Text
        textAlign='center'
        color='subdued'
        weight='bold'
        css={css`
          margin: 0;
        `}
      >
        Failed to load image
      </Text>
      {!!alt && (
        <Text
          css={css`
            padding: ${theme.spacing(0.5)};
            border: 1px solid ${theme.colors.neutral20};
            border-radius: ${theme.borderRadius.medium};
            background: ${theme.colors.white};
          `}
          textAlign='center'
          size='small'
          weight='semibold'
          color='light'
        >
          {alt}
        </Text>
      )}
    </div>
    {allowRetry && retryCount < MAX_RETRY_LIMIT ? (
      <Button onClick={handleRetry} aria-label='Retry loading image'>
        Retry
      </Button>
    ) : null}
  </div>
);

export const ImageComponent: React.FC<ImageProps> = (props) => {
  const {
    src,
    alt,
    placeholderSrc,
    width,
    height,
    aspectRatio,
    borderRadius = 0,
    loading = 'lazy',
    className = '',
    containerStyle,
    containerClassName,
    onRetry,
    imageStyle: imgStyle,
    objectFit = 'cover',
    allowRetry = true,
    ...rest
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const { state, isImageAlreadyLoaded, imageControls, handleImgRef, onLoad, onError, handleRetry } = useImageLoader(
    src || '',
    onRetry
  );

  const containerStyleCss = useMemo(
    () => [
      imageContainerStyle,
      css({
        width,
        height,
        aspectRatio,
        borderRadius,
      }),
      containerStyle,
    ],
    [width, height, aspectRatio, borderRadius, containerStyle]
  );

  const imageStyles = useMemo(
    () => [
      imageStyle,
      css`
        object-fit: ${objectFit};
      `,
      imgStyle,
    ],
    [objectFit, imgStyle]
  );

  return (
    <div
      ref={containerRef}
      css={containerStyleCss}
      className={`${className} ${containerClassName || ''}`}
      aria-busy={state.status === 'loading'}
    >
      <AnimatePresence>
        {state.status === 'loading' && (
          <Placeholder placeholderSrc={placeholderSrc} alt={alt} imageStyles={imageStyles} />
        )}
      </AnimatePresence>

      {state.status !== 'error' && (
        <motion.img
          src={src}
          alt={alt}
          css={imageStyles}
          loading={loading}
          onLoad={onLoad}
          onError={onError}
          ref={handleImgRef}
          animate={imageControls}
          initial={{ scale: isImageAlreadyLoaded ? 1 : 1.2 }}
          {...rest}
        />
      )}

      {state.status === 'error' && (
        <ErrorState alt={alt} allowRetry={allowRetry} retryCount={state.retryCount} handleRetry={handleRetry} />
      )}
    </div>
  );
};

const imageContainerStyle = css`
  position: relative;
  overflow: hidden;
  max-width: 100%;
`;

const imageStyle = css`
  width: 100%;
  height: 100%;
`;

const placeholderStyle = css`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: ${theme.zIndex.low};
`;

const errorContainerStyle = css`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.neutral5};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: inherit;
  z-index: ${theme.zIndex.middle};
`;

export { ImageComponent as Image };
