import { forwardRef, ReactNode, Ref } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { FloatingFocusManager, FloatingNode, FloatingPortal, useFloating } from '@floating-ui/react';
import { AnimatePresence, motion, MotionProps } from 'motion/react';
import { useThemeValues } from '../../../hooks';
import type { UsePopoverDialogResponse } from './use-popover-dialog';

type Props = ReturnType<UsePopoverDialogResponse['getDialogProps']> & {
  children: ReactNode;
  layoutParent?: boolean | 'position' | 'size';
  css?: SerializedStyles | any;
  returnFocus?: boolean;
} & Pick<MotionProps, 'initial' | 'animate' | 'exit' | 'transition'>;

const Dialog = forwardRef(
  (
    {
      isOpen,
      children,
      labelId,
      descriptionId,
      layoutParent,
      ...popoverProps
    }: Omit<Props, 'context' | 'nodeId' | 'initialFocus'>,
    ref: Ref<HTMLDialogElement>
  ) => {
    const theme = useThemeValues();

    const { onAnimationStart, onDragStart, onDragEnd, onDrag, ...motionCompatibleProps } = popoverProps;
    return (
      <motion.dialog
        initial={{ opacity: 0, y: 8 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 8 }}
        ref={ref}
        layout={layoutParent}
        open={isOpen}
        css={css`
          box-shadow: ${theme.shadows.heavy};
          border: none;
          overflow: auto;
          background: white;
          :focus {
            outline: none;
          }
          z-index: ${theme.zIndex.popover};
        `}
        aria-describedby={descriptionId}
        aria-labelledby={labelId}
        {...motionCompatibleProps}
      >
        {children}
      </motion.dialog>
    );
  }
);

export const PopoverDialog = forwardRef(
  (
    { isOpen, nodeId, context, layoutParent, returnFocus = true, initialFocus, ...props }: Props,
    ref: Ref<HTMLDialogElement> | undefined
  ) => {
    useFloating({ nodeId: nodeId });

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <AnimatePresence>
            {isOpen && (
              <FloatingFocusManager context={context} initialFocus={initialFocus} returnFocus={returnFocus}>
                <Dialog {...props} isOpen={isOpen} ref={ref} />
              </FloatingFocusManager>
            )}
          </AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    );
  }
);
