import { ReactNode } from 'react';
import { Text } from '../../../text';

type TextBubbleProps = {
  text: ReactNode;
  subText?: ReactNode;
  variant?: 'deleted' | 'text';
};
export const TextBubble = ({ text, subText, variant }: TextBubbleProps) => {
  return (
    <div className='bubble-content'>
      {typeof text === 'string' ? (
        <Text
          size='large'
          css={{ wordBreak: 'break-word' }}
          className='bubble-text'
          color={variant === 'deleted' ? 'subdued' : 'default'}
        >
          {text}
        </Text>
      ) : (
        text
      )}
      {subText &&
        (typeof subText === 'string' ? (
          <Text size='medium' className='bubble-text'>
            {subText}
          </Text>
        ) : (
          subText
        ))}
    </div>
  );
};
