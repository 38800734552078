import { useEffect, HTMLAttributes, ReactNode, useState, SetStateAction, Dispatch } from 'react';
import { css } from '@emotion/react';
import { cloneDeep } from 'lodash-es';
import { Transforms, Range, Editor, Path } from 'slate';
import { Editable, useFocused, useSelected, ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import { genUUIDV4 } from '@frontend/string';
import { genUID } from '../../../helpers';
import { contextFactory } from '../../../hooks';
import { useStyles } from '../../../use-styles';
import { EmojiButton } from '../../emoji-picker';
import { Text } from '../../text';
import { TemplateCharCount } from '../char-count';
import { insertTextAtSelection } from '../message-template-utils';
import { useTemplateSettings, useTemplateTags } from '../message-template.context';
import { TAG_ELEMENT_TYPE } from '../message-template.models';
import { MessageTemplateTag } from '../tags/message-template-tag';
import { TagType } from '../types';

const TagElement = ({ attributes, children, element }: RenderElementProps) => {
  const focused = useFocused();
  const selected = useSelected();
  const editor = useSlate();
  const { draggableTags, sharedTags, setSharedTags } = useTemplateTags();
  const { readOnly } = useTemplateSettings();
  const { setTagIdBeingDragged } = useTemplateEditorContext();

  const tag = element.tag as TagType;

  return (
    <span {...attributes}>
      <MessageTemplateTag
        css={css({ margin: '4px 1px' })}
        contentEditable={false} //needed for slate
        tag={tag}
        placed
        draggable={draggableTags}
        error={tag?.invalid}
        selected={selected}
        focused={focused}
        readOnly={readOnly || tag?.readOnly}
        onRemove={
          tag.nonRemovable
            ? undefined
            : () => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.removeNodes(editor, { at: path, voids: true });
              }
        }
        sharedTags={sharedTags}
        setSharedTags={setSharedTags}
        onDragStart={() => {
          setTagIdBeingDragged(tag.id);
        }}
        onDrop={() => {
          setTagIdBeingDragged(undefined);
        }}
      />
      {children}
    </span>
  );
};

const insertTag = (
  editor: ReactEditor,
  tag: TagType,
  sharedProps?: {
    correspondingTags?: Record<string, TagType[]>;
    sharedTags?: TagType[];
    setSharedTags?: (tags: TagType[]) => void;
  },
  range?: Range,
  tagIdBeingDragged?: string
) => {
  const options = {
    type: TAG_ELEMENT_TYPE,
    tag,
    children: [{ text: '' }],
  };

  if (range) {
    Transforms.select(editor, range);
  }
  Transforms.insertNodes(editor, options);
  editor.insertText(' ');

  const childrenNodes = cloneDeep(editor.children);
  editor.children = childrenNodes.map((node) => {
    // @ts-ignore the children property will be an array but TS doesn't think it is
    const children = (node.children || []).map((item) => {
      const itemTag = item.tag;
      // item is a tag in the sharedTags list
      if (sharedProps) {
        const { sharedTags, correspondingTags, setSharedTags } = sharedProps;
        if (sharedTags && correspondingTags && itemTag?.key && sharedTags.find((t) => t.key === itemTag.key)) {
          const isSameTag = itemTag.key === tag.key;
          const correspondingTag =
            itemTag.key !== tag.key &&
            correspondingTags[itemTag.label] &&
            correspondingTags[tag.label]?.find((t) => t.key === itemTag.key);

          const newSharedTags = correspondingTags[tag.label];
          if (newSharedTags) {
            setSharedTags?.(newSharedTags);
          }

          if (isSameTag) {
            return { ...item, tag: { ...item.tag, ...tag } };
          } else if (correspondingTag) {
            return { ...item, tag: { ...item.tag, ...correspondingTag } };
          }
        }
      }

      return item;
    });

    return { children };
  });

  if (tagIdBeingDragged) {
    const childrenNodes2 = cloneDeep(editor.children);
    let pathToDelete: Path | undefined;
    editor.children = childrenNodes2.map((topNode, index) => {
      // @ts-ignore the children property will be an array but TS doesn't think it is
      const children = (topNode.children || []).map((node, nodeIndex) => {
        const nodeTag = node.tag as TagType | undefined;
        if (nodeTag?.id === tagIdBeingDragged) {
          pathToDelete = [index, nodeIndex];
        } else if (pathToDelete?.[0] === index && pathToDelete?.[1] === nodeIndex - 1 && node.text?.startsWith(' ')) {
          return {
            ...node,
            text: node.text.slice(1),
          };
        }
        return node;
      });
      return { children };
    });

    if (pathToDelete) {
      Transforms.removeNodes(editor, { at: pathToDelete, voids: true });
    }
  }

  ReactEditor.focus(editor);
};

const DefaultElement = (props) => {
  const { readOnly } = useTemplateSettings();
  return (
    <Text as='p' size='large' color={readOnly ? 'light' : 'dark'} css={css({ margin: 0 })} {...props.attributes}>
      {props.children}
    </Text>
  );
};

const renderElement = (props: RenderElementProps) => {
  switch (props.element.type) {
    case TAG_ELEMENT_TYPE:
      return <TagElement {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
};

export const [TemplateEditorContext, useTemplateEditorContext] = contextFactory<{
  tagIdBeingDragged?: string;
  setTagIdBeingDragged: Dispatch<SetStateAction<string | undefined>>;
}>();

type TemplateEditorProps = HTMLAttributes<HTMLDivElement> & {
  charCount?: boolean;
  charCountLimit?: number;
  exceedsLimit?: boolean;
  placeholder?: string;
  error?: ReactNode;
  helperText?: ReactNode;
  showEmojiPicker?: boolean;
  children?: ReactNode;
  trimWhitespace?: boolean;
};

export const TemplateEditor = ({
  charCount = false,
  charCountLimit,
  exceedsLimit,
  placeholder,
  children,
  error,
  helperText,
  showEmojiPicker,
  trimWhitespace,
  ...props
}: TemplateEditorProps) => {
  const editor = useSlate();
  const [editorSelection, setEditorSelection] = useState<Range | null>(null);
  const { handleAddTagToTemplate, correspondingTags, sharedTags, setSharedTags } = useTemplateTags();
  const { readOnly } = useTemplateSettings();
  const editorId = 'template-editor-' + genUID();
  const subtextToDisplay = error || helperText;
  const templateEditorStyle = useStyles('MessageTemplate', 'templateEditor', {
    readOnly,
    error: !!error,
    hasSubtext: !!subtextToDisplay,
  });

  useEffect(() => {
    handleAddTagToTemplate((_, tag) => {
      insertTag(editor, tag, { correspondingTags, sharedTags, setSharedTags });
    });
  }, []);

  const addEmoji = ({ emoji }) => {
    const end = Editor.end(editor, []);
    const range = editorSelection ?? { anchor: end, focus: end };
    setEditorSelection(insertTextAtSelection(editor, ` ${emoji}`, range));
  };

  const [tagIdBeingDragged, setTagIdBeingDragged] = useState<string>();

  return (
    <TemplateEditorContext.Provider value={{ tagIdBeingDragged, setTagIdBeingDragged }}>
      <div css={templateEditorStyle} {...props}>
        <Editable
          id={editorId}
          className='message-template-slate-editor'
          renderElement={renderElement}
          onDragEnter={(_) => {
            ReactEditor.focus(editor);
          }}
          onDragLeave={(e) => {
            const editorElement = document.getElementById(editorId);

            // onDragLeave fires when dragging over the children in the editor nodes,
            // so we need to make sure the mouse actually left the editor box
            const isInBox = (x: number, y: number, boundingRect: DOMRect) => {
              const offset = 1;
              return (
                x > boundingRect.left - offset &&
                x < boundingRect.right - offset &&
                y > boundingRect.top - offset &&
                y < boundingRect.bottom - offset
              );
            };

            if (editorElement && !isInBox(e.clientX, e.clientY, editorElement.getBoundingClientRect())) {
              ReactEditor.blur(editor);
            }
          }}
          onDrop={(e) => {
            e.preventDefault();
            // prevents anything other than template tags from being dropped into the editor
            try {
              const data = e.dataTransfer.getData('text/plain');
              const { id, ...parsedTag } = JSON.parse(data) as TagType;
              const range = ReactEditor.findEventRange(editor, e);
              insertTag(
                editor,
                { ...parsedTag, id: genUUIDV4() },
                { correspondingTags, sharedTags, setSharedTags },
                range,
                tagIdBeingDragged
              );
              setTagIdBeingDragged(undefined);
            } catch (e) {
              console.warn('Failed to parse dropped tag');
            }
            return true;
          }}
          placeholder={placeholder ?? 'Type your message'}
          readOnly={readOnly}
          spellCheck
          onBlur={() => setEditorSelection(editor.selection)}
        />
        <div className='extra-actions'>
          {showEmojiPicker && (
            <EmojiButton onSelect={addEmoji} trackingId={'template-emoji-button-click'} pickerWidth={350} />
          )}
          {children}
        </div>
        {charCount && (
          <TemplateCharCount hasError={exceedsLimit} limit={charCountLimit} trimWhitespace={trimWhitespace} />
        )}
      </div>
      {!!subtextToDisplay && (
        <Text size='small' color={error ? 'error' : 'light'}>
          {subtextToDisplay}
        </Text>
      )}
    </TemplateEditorContext.Provider>
  );
};
