import { HTMLAttributes, useRef } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { motion } from 'motion/react';
import { useScrollShadow } from '../../hooks';
import { TabBar, ScrollButton } from './atoms';
import { useActiveTab } from './tabs.provider';
import { handleButtonScroll } from './utils';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: Array<JSX.Element | boolean | null>;
  fullWidth?: boolean;
};

export const TabList = ({ children, ...rest }: Props) => {
  const { scrollRef, isLeft, isRight, isScrollable } = useScrollShadow();
  const tabListRef = useRef<HTMLDivElement | null>(null);
  const { activeTab, uniqueId } = useActiveTab();

  return (
    <motion.div
      layout
      layoutRoot
      css={css`
        display: flex;
        position: relative;
      `}
    >
      <ScrollButton
        direction='left'
        disabled={isLeft}
        show={isScrollable}
        onClick={() => handleButtonScroll('left', tabListRef.current)}
      />
      <TabBar
        {...rest}
        isScrollable={isScrollable}
        ref={composeRefs(scrollRef, tabListRef)}
        activeTab={activeTab}
        role='tablist'
        uniqueId={uniqueId}
        tabIndex={-1}
      >
        {children}
      </TabBar>
      <ScrollButton
        direction='right'
        disabled={isRight}
        show={isScrollable}
        onClick={() => handleButtonScroll('right', tabListRef.current)}
      />
    </motion.div>
  );
};
