import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { AlertsProvider } from '@frontend/alert-system';
import envs from '@frontend/env';
import { ShellTitleBar } from '@frontend/shell-theme';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { ThemeProvider } from '@frontend/design-system';
import { ErrorBoundary } from './components/error-boundary';
import UsernameForm from './views/username-form';

const hasShellThemeEnabled =
  window.shell && window.shell?.isShell && window.shell?.featureAvailability?.has('shell-theme');

sentry.init({
  dsn: envs.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({ instrumentNavigation: true })],
  tracesSampleRate: 0.1,
});

export default function Root() {
  console.log('version: - ', envs.VERSION);
  /* on first load do these checks:
      handle routing, is it a call back, magic link, password reset, etc.
      check if user is already signed in, if so, redirect to the requested app
      initialize state key so we can have multiple tabs open and logging into different apps
  */

  return (
    <ThemeProvider includeEmotionTheme skipAnimation={!!window?.Cypress}>
      <Sentry.ErrorBoundary fallback={(props) => <ErrorBoundary {...props} />}>
        <AlertsProvider>
          {hasShellThemeEnabled && <ShellTitleBar />}
          <div
            css={css`
              display: flex;
              flex: 1;
              justify-content: center;
            `}
          >
            <main
              css={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                background-color: ${theme.colors.white};
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                `}
              >
                <UsernameForm />
              </div>
            </main>
          </div>
        </AlertsProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
}

declare global {
  interface Window {
    Cypress?: any;
  }
}
