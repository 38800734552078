import React from 'react';
import { css } from '@emotion/react';
import { transparentize } from 'color2k';
import { AnimatePresence, motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { KeyNames } from '../../../constants';
import { useSliderContext } from '../provider';

interface ThumbProps {
  index: number;
  ariaLabel: string;
}

export const Thumb = ({ index, ariaLabel }: ThumbProps) => {
  const {
    value,
    isRange,
    min,
    max,
    step,
    valueDisplay,
    formatValue,
    updateValue,
    getPercentage,
    thumbState,
    thumbDispatch,
  } = useSliderContext();

  const thumbValue = isRange && Array.isArray(value) ? value[index]! : (value as number);
  const position = getPercentage(thumbValue);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    thumbDispatch({ type: 'SET_DRAGGING', payload: index as 0 | 1 });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    let newValue = thumbValue;

    switch (event.key) {
      case KeyNames.Left:
      case KeyNames.Down:
        newValue = Math.max(newValue - step, min);
        break;
      case KeyNames.Right:
      case KeyNames.Up:
        newValue = Math.min(newValue + step, max);
        break;
      case KeyNames.Home:
        newValue = min;
        break;
      case KeyNames.End:
        newValue = max;
        break;
      default:
        return;
    }

    event.preventDefault();
    updateValue(newValue, index as 0 | 1);
  };

  const showValue =
    valueDisplay === 'always' ||
    (valueDisplay === 'hover' &&
      (thumbState.isDragging === index || thumbState.hoveredThumb === index || thumbState.focusedThumb === index));

  return (
    <div
      css={thumbStyles}
      style={{ left: `${position}%` }}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
      onMouseEnter={() => thumbDispatch({ type: 'SET_HOVERED', payload: index as 0 | 1 })}
      onMouseLeave={() => thumbDispatch({ type: 'SET_HOVERED', payload: null })}
      onFocus={() => thumbDispatch({ type: 'SET_FOCUSED', payload: index as 0 | 1 })}
      onBlur={() => thumbDispatch({ type: 'SET_FOCUSED', payload: null })}
      tabIndex={0}
      role='slider'
      aria-label={ariaLabel}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={thumbValue}
    >
      <AnimatePresence>
        {showValue && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.25 }}
            css={valueStyles}
          >
            {formatValue ? formatValue(thumbValue) : thumbValue}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const thumbStyles = css`
  position: absolute;
  top: 50%;
  width: 28px;
  height: 28px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: white;
  box-shadow: 0px 6px 13px 0px #0000001f, 0px 0.5px 4px 0px #0000001f;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 8px ${transparentize(theme.colors.primary50, 0.9)};
    outline: none;
  }

  &:active {
    box-shadow: 0 0 0 10px ${transparentize(theme.colors.primary50, 0.8)};
  }
`;

const valueStyles = css`
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${theme.colors.neutral90};
  font-size: ${theme.font.size.medium};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.white};
  padding: ${theme.spacing(0.5, 1)};
  border-radius: ${theme.borderRadius.small};
  white-space: nowrap;

  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.colors.neutral90} transparent transparent transparent;
  }
`;
