import React, { useState } from 'react';
import { Target, TargetAndTransition, VariantLabels } from 'motion/react';
import { getProtectedId } from '../../../helpers';
import { useUniqueID } from '../../../hooks';
import { contextFactory } from '../../../hooks';
import { IconProps } from '../../../icon';
import { ACCORDION_ERRORS } from '../accordion-errors';
import { AccordionValue, AccordionChevronPosition, AccordionHeadingLevel, AccordionVariant } from '../accordion.types';

type TargetResolver = (custom: any, current: Target, velocity: Target) => TargetAndTransition | string;
type AnimationDefinition = VariantLabels | TargetAndTransition | TargetResolver;

export type AccordionProviderProps<Multiple extends boolean = false> = {
  /** Determines whether multiple items can be opened at a time */
  isMulti?: Multiple;

  /** Value for controlled component */
  controlledValue?: AccordionValue<Multiple>;

  /** Default value for uncontrolled component */
  startingValue?: AccordionValue<Multiple>;

  /** Callback for controlled component */
  onChange?: (value: AccordionValue<Multiple>) => void;

  /** Callback for when the opening/closing animation starts*/
  onAnimationStart?: () => void;

  /**
   * Determines if Accordion headers include interactive elements.
   * When this option is true, opening/closing trigger moves to the
   * chevron icon.
   */
  isInteractive?: boolean;

  /** Callback for when the opening/closing animation ends*/
  onAnimationEnd?: () => void;

  /**
   * Callback when animation defined in `animate` is complete i.e when the animation/transition for expand/collapse ends
   *
   * The provided callback will be called with the triggering animation definition.
   *
   * This way, it's possible to figure out which animation has completed.
   *
   * ```jsx
   * function onComplete() {
   *   log("Animation completed")
   * }
   *
   * <motion.div
   *   animate={{ x: 100 }}
   *   onAnimationComplete={definition => {
   *     log('Completed animating', definition)
   *   }}
   * />
   * ```
   */
  onAnimationComplete?: (definition: AnimationDefinition) => void;

  /** Determines position of the chevron */
  chevronPosition?: AccordionChevronPosition;

  /** Heading level, has no effect on visuals */
  headingLevel?: AccordionHeadingLevel;

  /** Replaces chevron on all items */
  chevronIcon?: React.FC<React.PropsWithChildren<IconProps>> | boolean;

  /** Style variants that are available */
  variant?: AccordionVariant;

  /** Determines whether arrow key presses should shouldLoop though items (first to last and last to first) */
  shouldLoop?: boolean;

  /**
   * The purpose of this prop is to decide whether or not the AccordionBody should be displayed on the DOM even when it is closed.
   * When set to true, the AccordionBody will be present on the DOM, but will remain hidden using CSS.
   */
  shouldRenderBody?: boolean;

  chevronSize?: number;
  padding?: number | number[];
  distance?: number;
  size?: 'small' | 'large';
  hasMultiLocations?: boolean;
  showBoxShadow?: boolean;
  showBorderBottomAlways?: boolean;
  startAdornment?: React.ReactNode;
  children: React.ReactNode;
};

type AccordionContext = {
  shouldLoop?: boolean;
  shouldRenderBody?: boolean;
  chevronPosition?: AccordionChevronPosition;
  chevronSize?: number;
  headingLevel?: AccordionHeadingLevel;
  chevronIcon?: React.FC<React.PropsWithChildren<IconProps>> | boolean;
  variant?: AccordionVariant;
  isInteractive?: boolean;
  onChange?: (value: string) => void;
  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;
  onAnimationComplete?: (definition: AnimationDefinition) => void;
  isItemActive(value: string): boolean;
  getHeaderId(value: string): string;
  getBodyId(value: string): string;
  padding?: number | number[];
  distance?: number;
  size?: 'small' | 'large';
  hasMultiLocations?: boolean;
  showBoxShadow?: boolean;
  showBorderBottomAlways?: boolean;
  startAdornment?: React.ReactNode;
};

export const [AccordionContext, useAccordionContext] = contextFactory<AccordionContext>();

export function AccordionProvider<Multiple extends boolean = false>({
  children,
  isMulti,
  controlledValue,
  startingValue,
  onChange,
  onAnimationStart,
  onAnimationEnd,
  onAnimationComplete,
  shouldLoop,
  isInteractive,
  chevronPosition,
  headingLevel,
  chevronIcon,
  variant,
  padding,
  shouldRenderBody,
  chevronSize,
  distance,
  hasMultiLocations,
  size,
  showBoxShadow,
  showBorderBottomAlways,
  startAdornment,
}: AccordionProviderProps<Multiple>) {
  const uniqueId = useUniqueID();
  const multipleOrSingle = !!isMulti ? ([] as any) : null;
  const [currentValue, setCurrentValue] = useState(startingValue !== undefined ? startingValue : multipleOrSingle);
  const finalValue = controlledValue !== undefined ? controlledValue : currentValue;
  const isItemActive = (itemValue: string) =>
    Array.isArray(finalValue) ? finalValue.includes(itemValue) : itemValue === finalValue;

  const handleItemChange = (itemValue: string) => {
    const nextValue: AccordionValue<Multiple> = Array.isArray(finalValue)
      ? finalValue.includes(itemValue)
        ? finalValue.filter((selectedValue) => selectedValue !== itemValue)
        : [...finalValue, itemValue]
      : itemValue === finalValue
      ? null
      : (itemValue as any);
    !controlledValue && setCurrentValue(nextValue);
    onChange?.(nextValue);
  };

  return (
    <AccordionContext.Provider
      value={{
        isItemActive,
        onChange: handleItemChange,
        getHeaderId: getProtectedId(`${uniqueId}-trigger`, ACCORDION_ERRORS.value),
        getBodyId: getProtectedId(`${uniqueId}-panel`, ACCORDION_ERRORS.value),
        onAnimationStart,
        onAnimationEnd,
        onAnimationComplete,
        isInteractive,
        chevronPosition,
        headingLevel,
        chevronIcon,
        shouldLoop,
        variant,
        shouldRenderBody,
        padding,
        chevronSize,
        distance,
        size,
        hasMultiLocations,
        showBoxShadow,
        showBorderBottomAlways,
        startAdornment,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
}
