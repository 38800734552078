import { forwardRef, useEffect, useMemo } from 'react';
import composeRefs from '@seznam/compose-react-refs';
import { Icon } from '@frontend/icons';
import { SpinningLoader } from '../loader';
import { useTooltip } from '../tooltip';
import { ButtonProps } from './button.type';
import { getIconColor, getLoaderColor, getVariantStyles } from './styles';

const eventsToDisable = ['onClick', 'onMouseDown', 'onMouseUp', 'onKeyDown', 'onKeyUp'];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      destructive = false,
      disabled = false,
      hoverLabel = '',
      hoverLabelPlacement = 'top',
      iconName,
      loading = false,
      showLabelWhenDisabled = false,
      size = 'small',
      trackingId,
      type = 'button',
      variant = 'primary',
      label,
      ...rest
    },
    ref
  ) => {
    const iconOnly = !children && !!iconName;

    const buttonStyles = useMemo(() => {
      // getVariantStyles() has a few method calls. Better to memoize if props aren't changing.
      return getVariantStyles({ variant, size, loading, iconOnly, destructive });
    }, [variant, size, loading, iconOnly, destructive]);

    const effectivelyDisabled = disabled || loading;

    const eventHandlers = eventsToDisable.reduce((handlers, eventName) => {
      handlers[eventName] = (event) => {
        if (effectivelyDisabled) {
          event.preventDefault();
          return;
        }
        rest[eventName]?.(event);
      };
      return handlers;
    }, {});

    const { Tooltip, tooltipProps, triggerProps, isOpen, setOpen } = useTooltip({
      placement: hoverLabelPlacement,
    });
    // if hover label is visible when button is disabled
    // it can hang around until the button is reenabled
    useEffect(() => {
      if (disabled && !showLabelWhenDisabled && isOpen) {
        setOpen(false);
      }
    }, [disabled, isOpen]);

    return (
      <button
        aria-disabled={effectivelyDisabled}
        css={buttonStyles}
        data-trackingid={trackingId}
        ref={composeRefs(ref, triggerProps.ref) as React.Ref<HTMLButtonElement>}
        tabIndex={effectivelyDisabled ? -1 : 0}
        type={type}
        aria-label={label}
        {...rest}
        {...eventHandlers}
      >
        {loading ? (
          <SpinningLoader color={getLoaderColor(variant, destructive)} css={{ margin: 0 }} size='xs' />
        ) : (
          <>
            {!!hoverLabel && <Tooltip {...tooltipProps}>{hoverLabel}</Tooltip>}
            <span css={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              {iconName && (
                <Icon
                  name={iconName}
                  className='button-icon'
                  color={getIconColor(variant, disabled, destructive)}
                  size={size === 'large' ? 24 : 16}
                />
              )}
              {children}
            </span>
          </>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';
