import { css } from '@emotion/react';
import { audioScrubberTheme, audioScrubberThemeOriginal, AudioScrubberThemeValues } from './audio-scrubber-theme';
import { AudioState } from './types';

type ScrubberPropType = Pick<AudioState, 'currentTime' | 'duration' | 'hasError'> & {
  alwaysShowProgressButton?: boolean;
};

const styles = (
  { rangeBackgroundColor, rangeProgressColor, progressSliderThumbColor }: AudioScrubberThemeValues,
  { currentTime = 0, duration, hasError, alwaysShowProgressButton }: ScrubberPropType
) => {
  const THUMB_SIZE = 14;
  let percent = 0;
  if (duration > 0) {
    percent = (currentTime / duration) * 100;
  }
  return [
    duration > 0 && currentTime > 0
      ? css`
          background: linear-gradient(
            to right,
            ${rangeProgressColor} 0%,
            ${rangeProgressColor} ${percent}%,
            ${rangeBackgroundColor} ${percent}%,
            ${rangeBackgroundColor} 100%
          );
        `
      : css`
          background: ${rangeBackgroundColor};
        `,
    css`
      -webkit-appearance: none;
      appearance: none;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      height: 5px;
      margin: 0;
      outline: none;
      padding: 0;
      width: 100%;
    `,
    css`
      &:hover {
        &::-webkit-slider-thumb {
          opacity: 1;
        }
      }

      &::-webkit-slider-thumb {
        appearance: none;
        background: ${progressSliderThumbColor};
        border-radius: 50%;
        cursor: pointer;
        height: ${THUMB_SIZE}px;
        opacity: ${alwaysShowProgressButton ? 1 : 0};
        transition: opacity 200ms;
        width: ${THUMB_SIZE}px;
      }

      &::-moz-range-thumb {
        background: ${progressSliderThumbColor};
        border-radius: 50%;
        cursor: pointer;
        height: ${THUMB_SIZE}px;
        width: ${THUMB_SIZE}px;
        border-color: ${progressSliderThumbColor};
      }
    `,
    hasError &&
      css`
        & {
          cursor: default;

          &::-webkit-range-thumb {
            display: none;
          }

          &::-moz-range-thumb {
            display: none;
          }
        }
      `,
  ];
};

export const AudioScrubberStyles = {
  AudioScrubber: (scrubberProps: ScrubberPropType) => styles(audioScrubberTheme, scrubberProps),
};

export const AudioScrubberStylesOriginal: typeof AudioScrubberStyles = {
  AudioScrubber: (scrubberProps: ScrubberPropType) => styles(audioScrubberThemeOriginal, scrubberProps),
};
