import { Children, forwardRef, ReactNode, useMemo } from 'react';
import {
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  useFloating,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'motion/react';
import { Heading } from '../../';
import { useStyles } from '../../../use-styles';
import { UsePopoverMenuResponse } from './use-popover-menu';

export type PopoverMenuProps<T extends HTMLElement = HTMLMenuElement> = ReturnType<
  UsePopoverMenuResponse<T>['getMenuProps']
> & {
  title?: string;
  initialFocus?: Parameters<typeof FloatingFocusManager>[0]['initialFocus'];
  children: ReactNode;
  returnFocus?: boolean;
  alwaysRender?: boolean;
};

export const createPopoverMenuVariants = (alwaysRender: boolean) => ({
  initial: {
    opacity: 0,
    transform: 'translateY(8px)',

    ...(alwaysRender && { display: 'none' }),
  },
  visible: {
    opacity: 1,
    transform: 'translateY(0)',
    ...(alwaysRender && { display: 'block' }),
  },
  hidden: {
    opacity: 0,
    transform: 'translateY(8px)',
    ...(alwaysRender && { display: 'none' }),
  },
});

// eslint-disable-next-line react/display-name
export const PopoverMenu = forwardRef<HTMLMenuElement, PopoverMenuProps>(
  (
    {
      children,
      context,
      isOpen,
      title,
      initialFocus,
      returnFocus,
      alwaysRender,
      listItemsRef,
      listContentRef,
      ...popoverProps
    },
    ref
  ) => {
    const styles = useStyles('PopoverMenu', 'menu');
    useFloating({ nodeId: popoverProps.nodeId });
    const { onAnimationStart, onDragStart, onDragEnd, onDrag, nodeId, ...motionCompatibleProps } = popoverProps;
    const count = Children.count(children);

    const variants = useMemo(() => createPopoverMenuVariants(!!alwaysRender), [alwaysRender]);

    const shouldOpen = isOpen && count > 0;
    return (
      <FloatingNode id={nodeId}>
        <FloatingList elementsRef={listItemsRef} labelsRef={listContentRef}>
          <FloatingPortal>
            <ConditionalRender condition={shouldOpen || !!alwaysRender}>
              <FloatingFocusManager context={context} initialFocus={initialFocus ?? 0} returnFocus={returnFocus}>
                <FloatingTree>
                  <motion.menu
                    css={styles}
                    variants={variants}
                    initial='initial'
                    animate={shouldOpen ? 'visible' : 'hidden'}
                    exit={'hidden'}
                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                    ref={ref}
                    {...motionCompatibleProps}
                  >
                    {!!title && <Heading level={2}>{title}</Heading>}
                    {children}
                  </motion.menu>
                </FloatingTree>
              </FloatingFocusManager>
            </ConditionalRender>
          </FloatingPortal>
        </FloatingList>
      </FloatingNode>
    );
  }
);

const ConditionalRender = ({ children, condition }: { children: ReactNode; condition: boolean }) => (
  <AnimatePresence>{condition ? <>{children}</> : null}</AnimatePresence>
);
