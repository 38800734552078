import { theme as themeOriginal } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type PopoverMenuItemThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  focusedBorderColor: WeaveThemeValues['Color'];
  dividerColor?: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type PopoverMenuThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  shadows: WeaveThemeValues['BoxShadow'];
  fontSize: WeaveThemeValues['FontSize'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export const popoverMenuItemTheme: PopoverMenuItemThemeValues = {
  backgroundColor: theme.colors.neutral5,
  borderColor: theme.colors.neutral20,
  disabledColor: theme.colors.status.disabled,
  focusedBorderColor: theme.colors.primary50,
  spacing: theme.spacing,
  dividerColor: theme.colors.neutral20,
};

export const popoverMenuItemThemeOriginal: PopoverMenuItemThemeValues = {
  backgroundColor: themeOriginal.colors.gray[200],
  borderColor: themeOriginal.colors.gray[300],
  disabledColor: themeOriginal.colors.disabled,
  focusedBorderColor: themeOriginal.colors.weaveBlue,
  spacing: themeOriginal.spacing,
  dividerColor: themeOriginal.colors.gray[300],
};

export const popoverMenuTheme: PopoverMenuThemeValues = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.medium,
  shadows: theme.shadows.heavy,
  fontSize: theme.fontSize,
  zIndex: theme.zIndex.popover,
};
export const popoverMenuThemeOriginal: PopoverMenuThemeValues = {
  spacing: themeOriginal.spacing,
  borderRadius: themeOriginal.borderRadius.medium,
  shadows: themeOriginal.shadows.heavy,
  fontSize: themeOriginal.fontSize,
  zIndex: themeOriginal.zIndex.popover,
};
